import React from 'react';
import { Users } from 'lucide-react';
import { StaffListItem } from './StaffListItem';
import type { Staff } from '../../../lib/types';

interface StaffListProps {
  staff: Staff[];
  onEdit: (staff: Staff) => void;
  onDelete: (id: string) => Promise<void>;
  error?: string;
}

export function StaffList({ staff, onEdit, onDelete, error }: StaffListProps) {
  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
        {error}
      </div>
    );
  }

  if (staff.length === 0) {
    return (
      <div className="bg-white shadow rounded-lg">
        <div className="p-6">
          <div className="flex flex-col items-center justify-center py-12">
            <Users className="h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">No staff members added yet</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {staff.map((member) => (
        <StaffListItem
          key={member.id}
          staff={member}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}