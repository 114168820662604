import React from 'react';
import { Link } from 'react-router-dom';

export function FooterNav() {
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-semibold text-gray-900">Product</h3>
      <ul className="space-y-3">
        <li>
          <button
            onClick={() => scrollToSection('features')}
            className="text-sm text-gray-500 hover:text-gray-900"
          >
            Features
          </button>
        </li>
        <li>
          <button
            onClick={() => scrollToSection('pricing')}
            className="text-sm text-gray-500 hover:text-gray-900"
          >
            Pricing
          </button>
        </li>
        <li>
          <Link to="/register" className="text-sm text-gray-500 hover:text-gray-900">
            Get Started
          </Link>
        </li>
        <li>
          <Link to="/login" className="text-sm text-gray-500 hover:text-gray-900">
            Sign In
          </Link>
        </li>
      </ul>
    </div>
  );
}