import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Mail, MessageSquare } from 'lucide-react';

declare global {
  interface Window {
    hbspt: any;
  }
}

export function ContactPage() {
  useEffect(() => {
    // Load HubSpot script
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "2539729",
          formId: "f413582f-e753-430a-9186-51572cd9b7c4",
          target: '#hubspot-form-container'
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="max-w-2xl mx-auto">
          <Link to="/" className="text-indigo-600 hover:text-indigo-500 mb-8 inline-block">
            ← Back to Home
          </Link>

          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
              Contact Us
            </h1>
            <p className="text-lg text-gray-600">
              Have questions? We'd love to hear from you.
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            {/* Contact Info */}
            <div className="p-6 bg-gray-50 border-b border-gray-200">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex items-center">
                  <Mail className="h-6 w-6 text-indigo-600 flex-shrink-0" />
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-900">Email</h3>
                    <p className="text-sm text-gray-600">
                      <a href="mailto:info@appointflex.com" className="hover:text-indigo-600">
                        info@appointflex.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <MessageSquare className="h-6 w-6 text-indigo-600 flex-shrink-0" />
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-900">Support</h3>
                    <p className="text-sm text-gray-600">
                      We typically respond within 24 hours
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* HubSpot Form Container */}
            <div className="p-6">
              <div id="hubspot-form-container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}