import React, { useState } from 'react';
import { format, startOfWeek, addDays, isSameDay, isToday } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import type { Appointment } from '../../lib/api/appointments/types';
import { Button } from '../ui/Button';

interface AppointmentCalendarProps {
  appointments: Appointment[];
  selectedDate: Date;
  onDateSelect: (date: Date) => void;
}

export function AppointmentCalendar({ appointments, selectedDate, onDateSelect }: AppointmentCalendarProps) {
  const [currentWeek, setCurrentWeek] = useState(startOfWeek(selectedDate));

  const days = Array.from({ length: 7 }, (_, i) => addDays(currentWeek, i));

  const navigateWeek = (direction: 'prev' | 'next') => {
    setCurrentWeek(current => addDays(current, direction === 'next' ? 7 : -7));
  };

  return (
    <div className="bg-white rounded-lg shadow">
      {/* Calendar Header */}
      <div className="flex items-center justify-between p-4 border-b">
        <Button variant="secondary" size="sm" onClick={() => navigateWeek('prev')}>
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <h2 className="text-sm font-medium text-gray-900">
          {format(currentWeek, 'MMMM d')} - {format(addDays(currentWeek, 6), 'MMMM d, yyyy')}
        </h2>
        <Button variant="secondary" size="sm" onClick={() => navigateWeek('next')}>
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      
      {/* Day Headers */}
      <div className="grid grid-cols-7 border-b">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="py-2 text-center text-xs font-medium text-gray-500">
            {day}
          </div>
        ))}
      </div>
      
      {/* Calendar Grid */}
      <div className="grid grid-cols-7 divide-x divide-y">
        {days.map((day) => {
          const isSelected = isSameDay(day, selectedDate);
          const dayAppointments = appointments.filter(apt => 
            isSameDay(new Date(apt.start_time), day)
          );

          return (
            <button
              key={day.toISOString()}
              onClick={() => onDateSelect(day)}
              className={`
                min-h-[100px] p-1 hover:bg-gray-50 transition-colors relative
                ${isSelected ? 'bg-indigo-50' : 'bg-white'}
              `}
            >
              <span className={`
                inline-flex h-5 w-5 items-center justify-center rounded-full text-xs
                ${isToday(day) ? 'bg-indigo-600 text-white' : ''}
                ${isSelected && !isToday(day) ? 'text-indigo-600 font-semibold' : ''}
              `}>
                {format(day, 'd')}
              </span>
              
              <div className="mt-1 space-y-1">
                {dayAppointments.map((apt) => (
                  <div
                    key={apt.id}
                    className={`
                      text-xs p-1 rounded truncate
                      ${getAppointmentColor(apt.status)}
                    `}
                  >
                    <div className="font-medium truncate">
                      {format(new Date(apt.start_time), 'HH:mm')}
                    </div>
                    <div className="truncate text-[10px]">
                      {apt.customers?.name || 'No name'}
                    </div>
                  </div>
                ))}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

function getAppointmentColor(status: string): string {
  switch (status) {
    case 'confirmed':
      return 'bg-green-100 text-green-800';
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'cancelled':
      return 'bg-red-100 text-red-800';
    case 'completed':
      return 'bg-blue-100 text-blue-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}