import React from 'react';
import { Link } from 'react-router-dom';

export function PrivacyPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <Link to="/" className="text-indigo-600 hover:text-indigo-500 mb-8 inline-block">
            ← Back to Home
          </Link>
          
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          
          <div className="prose prose-indigo max-w-none">
            <div className="text-sm text-gray-600 mb-8">
              For the User's Client's of the AppointFlex Online Booking Solution
            </div>

            <div className="bg-gray-50 p-4 rounded-lg mb-8">
              <p className="text-sm text-gray-600 italic">
                For your convenience, we provide you with translations of the English language version of these Terms & Conditions and Privacy Policy (collectively "Global Voice Connect Solution Terms"). In the event of any inconsistency between a non-English version of these Global Voice Connect Solution Terms and the English version, the English version shall be controlling in all respects and shall prevail.
              </p>
            </div>

            <h2 className="text-xl font-semibold mb-4">Data Processing</h2>
            <p className="mb-8">AppointFlex is the processor of data for the clients of the AppointFlex system. The user of the system, often the same as the service provider, is the controller of your data (the client data). You can request the controller of your data to provide, delete, or rectify all personal information they may hold about you.</p>

            <h2 className="text-xl font-semibold mb-4">Information We Collect</h2>
            <p className="mb-4">We collect and process the following types of information:</p>
            <ul className="list-disc pl-6 space-y-2 mb-8">
              <li>Basic contact information (name, email, phone number)</li>
              <li>Appointment booking details</li>
              <li>Service preferences and history</li>
              <li>Payment information (processed securely through our payment providers)</li>
            </ul>

            <h2 className="text-xl font-semibold mb-4">How We Use Your Information</h2>
            <p className="mb-4">Your information is used for:</p>
            <ul className="list-disc pl-6 space-y-2 mb-8">
              <li>Facilitating appointment bookings</li>
              <li>Processing payments</li>
              <li>Sending appointment reminders</li>
              <li>Improving our services</li>
              <li>Complying with legal obligations</li>
            </ul>

            <h2 className="text-xl font-semibold mb-4">Your Rights</h2>
            <p className="mb-4">You have the right to:</p>
            <ul className="list-disc pl-6 space-y-2 mb-8">
              <li>Access your personal data</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Object to processing of your data</li>
              <li>Request data portability</li>
            </ul>

            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <p>For privacy-related inquiries, please contact us at:</p>
            <p>Email: <a href="mailto:info@appointflex.com" className="text-indigo-600 hover:text-indigo-500">info@appointflex.com</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}