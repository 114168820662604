import React from 'react';
import { FooterNav } from './FooterNav';
import { FooterLegal } from './FooterLegal';
import { FooterBottom } from './FooterBottom';
import { Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Brand Column */}
          <div className="space-y-4">
            <Link to="/" className="flex items-center">
              <Calendar className="h-8 w-8 text-indigo-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">AppointFlex</span>
            </Link>
            <p className="text-sm text-gray-500">
              Streamline your business with smart appointment scheduling.
            </p>
          </div>

          {/* Navigation Links */}
          <FooterNav />

          {/* Legal Links */}
          <FooterLegal />

          {/* Contact Info */}
          <div className="space-y-4">
            <h3 className="text-sm font-semibold text-gray-900">Contact</h3>
            <ul className="space-y-3">
              <li>
                <a href="mailto:info@appointflex.com" className="text-sm text-gray-500 hover:text-gray-900">
                  info@appointflex.com
                </a>
              </li>
            </ul>
          </div>
        </div>

        <FooterBottom />
      </div>
    </footer>
  );
}