import React from 'react';
import { Link } from 'react-router-dom';

export function TermsPage() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <Link to="/" className="text-indigo-600 hover:text-indigo-500 mb-8 inline-block">
            ← Back to Home
          </Link>
          
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms and Conditions</h1>
          
          <div className="prose prose-indigo max-w-none">
            <div className="text-sm text-gray-600 mb-8">
              For the User's Client's of the AppointFlex Online Booking Solution
            </div>

            <div className="bg-gray-50 p-4 rounded-lg mb-8">
              <p className="text-sm text-gray-600 italic">
                For your convenience, we provide you with translations of the English language version of these Terms & Conditions and Privacy Policy (collectively "Global Voice Connect Solution Terms"). In the event of any inconsistency between a non-English version of these Global Voice Connect Solution Terms and the English version, the English version shall be controlling in all respects and shall prevail.
              </p>
            </div>

            <h2 className="text-xl font-semibold mb-4">Definitions</h2>
            <div className="space-y-4 mb-8">
              <div>
                <p className="font-medium">AppointFlex services or AppointFlex system</p>
                <p>Is a system provided by Global Voice Connect Solution DBA AppointFlex that allows system users to access various business functionalities that help them run their business. The AppointFlex system can run on a network of sites under various domain names, such as: AppointFlex.com and any other names that offer the same services.</p>
              </div>

              <div>
                <p className="font-medium">Service provider or user</p>
                <p>A company or person that provides services, rents tools, equipment, space, etc., and uses the AppointFlex system to accept and manage bookings online, send promotions, sell products, and perform other related activities that help them run their service business.</p>
              </div>

              <div>
                <p className="font-medium">Clients or service buyers</p>
                <p>Clients can be any person that books an appointment or purchases a product online with a service provider that has a user account with the AppointFlex system. This can, for example, be a patient, student, or client.</p>
              </div>
            </div>

            <div className="mb-8">
              <p>AppointFlex is the processor of data for the clients of the AppointFlex system. The user of the system, often the same as the service provider, is the controller of your data (the client data). You can request the controller of your data to provide, delete, or rectify all personal information they may hold about you.</p>
            </div>

            <h2 className="text-xl font-semibold mb-4">Acceptable Use Policy</h2>
            <p className="mb-4">If you use the AppointFlex system or related sites, you agree that you will not:</p>
            <ul className="list-disc pl-6 space-y-2 mb-8">
              <li>Violate any laws, third-party rights, or our policies;</li>
              <li>Use our sites and tools if you are not able to form legally binding contracts or are under the age of 18;</li>
              <li>Fail to deliver services ordered from you unless the buyer gets a refund or can receive a similar or same service at a different time and date agreed by both parties;</li>
              <li>Interfere with other users' listings;</li>
              <li>Post false, inaccurate, misleading, defamatory, or libelous content (including personal information) or use the service and site for link-building purposes;</li>
              <li>Take any action that may undermine feedback and/or rating systems;</li>
              <li>Distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes;</li>
              <li>Distribute viruses or any other technologies that may harm any visitors of the site;</li>
              <li>Harvest or otherwise collect information about users, including email addresses or credit cards, without their consent.</li>
            </ul>

            <h2 className="text-xl font-semibold mb-4">Client Purchase Protection</h2>
            <div className="space-y-4 mb-8">
              <p>AppointFlex does not offer any purchase guarantees concerning client payments for appointment bookings, service purchases, or other product purchases from service providers. AppointFlex offers service providers a way to sell their services and provides buyers, clients, or patients with a method to book these services.</p>
              <p>Service providers can use our tools to offer their clients the ability to pay for services online. Buyers and service providers share the responsibility for ensuring that purchases and service orders facilitated by AppointFlex are in good spirit, rewarding, and hassle-free.</p>
              <p>We encourage buyers to work with sellers before opening a complaint with AppointFlex. We take no responsibility for wrongful service orders, services provided by service providers, or services not provided by service providers, even if they are purchased and paid for.</p>
              <p>It is strongly recommended that buyers inform themselves about the service provider before booking with them or paying online, and for service providers to ensure buyers are genuine.</p>
            </div>

            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <p>Email: <a href="mailto:info@appointflex.com" className="text-indigo-600 hover:text-indigo-500">info@appointflex.com</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}