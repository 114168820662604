import React from 'react';
import { format } from 'date-fns';
import { Mail, Phone, Calendar, Edit2, Trash2 } from 'lucide-react';
import { Button } from '../../ui/Button';
import type { Staff } from '../../../lib/types';

interface StaffListItemProps {
  staff: Staff;
  onEdit: (staff: Staff) => void;
  onDelete: (id: string) => Promise<void>;
}

export function StaffListItem({ staff, onEdit, onDelete }: StaffListItemProps) {
  const handleDelete = async () => {
    if (confirm('Are you sure you want to delete this staff member?')) {
      await onDelete(staff.id);
    }
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4 hover:border-gray-300 transition-colors">
      <div className="flex justify-between items-start">
        <div className="flex items-start space-x-4">
          <div className="w-12 h-12 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-medium text-lg">
            {staff.name.split(' ').map(n => n[0]).join('')}
          </div>

          <div>
            <h3 className="font-medium text-gray-900">{staff.name}</h3>
            <p className="text-sm text-gray-500">{staff.role}</p>
            
            <div className="mt-2 space-y-1">
              <div className="flex items-center text-sm text-gray-500">
                <Mail className="h-4 w-4 mr-2" />
                {staff.email}
              </div>
              {staff.phone && (
                <div className="flex items-center text-sm text-gray-500">
                  <Phone className="h-4 w-4 mr-2" />
                  {staff.phone}
                </div>
              )}
              <div className="flex items-center text-sm text-gray-500">
                <Calendar className="h-4 w-4 mr-2" />
                Joined {format(new Date(staff.created_at), 'MMM d, yyyy')}
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-2">
          <Button
            variant="secondary"
            onClick={() => onEdit(staff)}
          >
            <Edit2 className="h-4 w-4" />
          </Button>
          <Button
            variant="secondary"
            onClick={handleDelete}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}