import React from 'react';
import { format } from 'date-fns';
import { User, Briefcase, Clock } from 'lucide-react';
import { Button } from '../ui/Button';
import { getStatusBadgeColor } from './utils';
import type { Appointment } from '../../lib/api/appointments/types';

interface AppointmentListItemProps {
  appointment: Appointment;
  onStatusChange: (id: string, status: string) => void;
}

export function AppointmentListItem({ appointment, onStatusChange }: AppointmentListItemProps) {
  const customerName = appointment.customers?.name || 'Customer Name Not Available';
  const serviceName = appointment.services?.name || 'Service Not Available';
  const staffName = appointment.staff?.name || 'Staff Not Available';

  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4 hover:border-gray-300 transition-colors">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center space-x-2">
          <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(appointment.status)}`}>
            {appointment.status}
          </span>
          <span className="flex items-center text-sm text-gray-500">
            <Clock className="h-4 w-4 mr-1" />
            {format(new Date(appointment.start_time), 'HH:mm')}
          </span>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center text-sm">
          <User className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
          <span className="font-medium truncate">{customerName}</span>
        </div>

        <div className="flex items-center text-sm">
          <Briefcase className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
          <div className="truncate">
            <span className="font-medium">{serviceName}</span>
            <span className="text-gray-500"> with {staffName}</span>
          </div>
        </div>
      </div>

      {appointment.notes && (
        <p className="mt-2 text-sm text-gray-500 border-t border-gray-100 pt-2 line-clamp-2">
          {appointment.notes}
        </p>
      )}

      <div className="mt-3 flex flex-wrap gap-2">
        {appointment.status === 'pending' && (
          <>
            <Button size="sm" variant="secondary" onClick={() => onStatusChange(appointment.id, 'confirmed')}>
              Confirm
            </Button>
            <Button size="sm" variant="secondary" onClick={() => onStatusChange(appointment.id, 'cancelled')}>
              Cancel
            </Button>
          </>
        )}
        {appointment.status === 'confirmed' && (
          <Button size="sm" variant="secondary" onClick={() => onStatusChange(appointment.id, 'completed')}>
            Complete
          </Button>
        )}
      </div>
    </div>
  );
}