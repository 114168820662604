import React from 'react';

export function FooterBottom() {
  return (
    <div className="mt-12 pt-8 border-t border-gray-200">
      <p className="text-sm text-gray-500 text-center">
        © {new Date().getFullYear()} AppointFlex. All rights reserved.
      </p>
    </div>
  );
}