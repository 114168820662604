import React, { useState } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { DashboardStats } from './components/DashboardStats';
import { AppointmentList } from './components/AppointmentList';
import { DashboardStaffList } from './components/DashboardStaffList';
import { QuickBookingForm } from '../../components/dashboard/QuickBookingForm';
import { Modal } from '../../components/ui/Modal';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import { Button } from '../../components/ui/Button';
import { Calendar } from 'lucide-react';

export function DashboardPage() {
  const { user } = useAuthStore();
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);

  const handleBookingSuccess = () => {
    setIsBookingModalOpen(false);
    // Optionally refresh data here
  };

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
            <p className="mt-1 text-sm text-gray-500">
              Here's what's happening with your business today.
            </p>
          </div>
          <div className="flex items-center space-x-4">
            {user?.id && (
              <Link 
                to={`/portal/${user.id}`} 
                target="_blank"
                className="text-sm text-gray-600 hover:text-gray-900"
              >
                Customer Portal
              </Link>
            )}
            <Button onClick={() => setIsBookingModalOpen(true)}>
              <Calendar className="h-4 w-4 mr-2" />
              New Booking
            </Button>
          </div>
        </div>

        <DashboardStats />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Today's Appointments */}
          <div className="bg-white shadow rounded-lg">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-medium text-gray-900">Today's Appointments</h2>
                <Link to="/dashboard/appointments" className="text-sm text-indigo-600 hover:text-indigo-900">
                  View all
                </Link>
              </div>
              <AppointmentList />
            </div>
          </div>

          {/* Staff Members */}
          <div className="bg-white shadow rounded-lg">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-medium text-gray-900">Staff Members</h2>
                <Link to="/dashboard/staff" className="text-sm text-indigo-600 hover:text-indigo-900">
                  Add staff
                </Link>
              </div>
              <DashboardStaffList />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isBookingModalOpen}
        onClose={() => setIsBookingModalOpen(false)}
        title="Quick Booking"
      >
        <QuickBookingForm
          onSuccess={handleBookingSuccess}
          onCancel={() => setIsBookingModalOpen(false)}
        />
      </Modal>
    </DashboardLayout>
  );
}