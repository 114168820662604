import React from 'react';
import type { StaffFormData } from './types';

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface WorkingHoursProps {
  schedule: StaffFormData['schedule'];
  onChange: (schedule: StaffFormData['schedule']) => void;
  disabled?: boolean;
}

export function WorkingHours({ schedule, onChange, disabled }: WorkingHoursProps) {
  const handleDayChange = (day: string, field: string, value: string | boolean) => {
    onChange({
      ...schedule,
      [day]: {
        ...schedule[day],
        [field]: value
      }
    });
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Working Hours
      </label>
      {DAYS.map((day) => (
        <div key={day} className="flex items-center space-x-4">
          <input
            type="checkbox"
            checked={schedule[day]?.enabled ?? false}
            onChange={(e) => handleDayChange(day, 'enabled', e.target.checked)}
            disabled={disabled}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <span className="text-sm text-gray-700 w-24">{day}</span>
          <input
            type="time"
            value={schedule[day]?.start ?? '09:00'}
            onChange={(e) => handleDayChange(day, 'start', e.target.value)}
            disabled={!schedule[day]?.enabled || disabled}
            className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <span className="text-sm text-gray-500">to</span>
          <input
            type="time"
            value={schedule[day]?.end ?? '17:00'}
            onChange={(e) => handleDayChange(day, 'end', e.target.value)}
            disabled={!schedule[day]?.enabled || disabled}
            className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      ))}
    </div>
  );
}