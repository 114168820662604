import { validateEmail, validatePhone } from '../../../utils/validation';
import type { StaffFormData } from './types';

export interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
}

export function validateStaffForm(data: StaffFormData): FormErrors {
  const errors: FormErrors = {};

  if (!data.name.trim()) {
    errors.name = 'Name is required';
  }

  const emailValidation = validateEmail(data.email);
  if (!emailValidation.isValid) {
    errors.email = emailValidation.message || 'Invalid email';
  }

  if (data.phone) {
    const phoneValidation = validatePhone(data.phone);
    if (!phoneValidation.isValid) {
      errors.phone = phoneValidation.message || 'Invalid phone number';
    }
  }

  if (!data.role.trim()) {
    errors.role = 'Role is required';
  }

  return errors;
}