import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { Button } from '../../components/ui/Button';
import { Modal } from '../../components/ui/Modal';
import { StaffForm } from '../../components/staff/StaffForm/StaffForm';
import { StaffList } from '../../components/staff/StaffList/StaffList';
import { staffService } from '../../lib/api/staff/service';
import { api } from '../../lib/api';
import type { Staff } from '../../lib/types';

export function StaffPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [editingStaff, setEditingStaff] = useState<Staff | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    loadStaff();
  }, []);

  const loadStaff = async () => {
    try {
      setIsLoading(true);
      const data = await api.staff.list();
      setStaff(data);
      setError('');
    } catch (err: any) {
      console.error('Failed to load staff:', err);
      setError(err.message || 'Failed to load staff members');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (id: string, data: Partial<Staff>) => {
    try {
      setIsLoading(true);
      await staffService.update(id, data);
      await loadStaff();
      setEditingStaff(null);
      setError('');
    } catch (err: any) {
      console.error('Failed to update staff member:', err);
      throw new Error(err.message || 'Failed to update staff member');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setIsLoading(true);
      await api.staff.delete(id);
      await loadStaff();
      setError('');
    } catch (err: any) {
      console.error('Failed to delete staff member:', err);
      setError(err.message || 'Failed to delete staff member');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && staff.length === 0) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Staff Management</h1>
            <p className="mt-1 text-sm text-gray-500">
              Add and manage your staff members
            </p>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>Add Staff Member</Button>
        </div>

        <StaffList
          staff={staff}
          onEdit={setEditingStaff}
          onDelete={handleDelete}
          error={error}
        />
      </div>

      {/* Add Staff Modal */}
      {isModalOpen && (
        <Modal
          isOpen={true}
          onClose={() => setIsModalOpen(false)}
          title="Add Staff Member"
        >
          <StaffForm
            onSubmit={async (data) => {
              try {
                await api.staff.create(data);
                await loadStaff();
                setIsModalOpen(false);
              } catch (err: any) {
                throw new Error(err.message || 'Failed to create staff member');
              }
            }}
            onCancel={() => setIsModalOpen(false)}
            isLoading={isLoading}
          />
        </Modal>
      )}

      {/* Edit Staff Modal */}
      {editingStaff && (
        <Modal
          isOpen={true}
          onClose={() => setEditingStaff(null)}
          title="Edit Staff Member"
        >
          <StaffForm
            initialData={{
              name: editingStaff.name,
              email: editingStaff.email,
              phone: editingStaff.phone || '',
              role: editingStaff.role,
              schedule: editingStaff.working_hours || {}
            }}
            onSubmit={async (data) => {
              await handleUpdate(editingStaff.id, data);
            }}
            onCancel={() => setEditingStaff(null)}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </DashboardLayout>
  );
}