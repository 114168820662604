import { supabase } from '../../supabase';
import { validateStaffData } from './validation';
import type { StaffUpdateData, StaffApiError } from './types';

export const staffService = {
  async update(id: string, data: StaffUpdateData): Promise<void> {
    try {
      // Validate data before sending to API
      const validationError = validateStaffData(data);
      if (validationError) {
        throw new Error(validationError);
      }

      // Clean up data before sending to API
      const cleanData = {
        name: data.name.trim(),
        email: data.email.trim().toLowerCase(),
        phone: data.phone?.trim() || null,
        role: data.role.trim(),
        working_hours: data.working_hours || {}
      };

      const { error } = await supabase
        .from('staff')
        .update(cleanData)
        .eq('id', id);

      if (error) {
        console.error('Staff update error:', error);
        
        // Handle specific database errors
        if (error.code === '23505') { // Unique constraint violation
          throw new Error('A staff member with this email already exists');
        }
        
        throw new Error(error.message || 'Failed to update staff member');
      }
    } catch (error) {
      console.error('Staff update error:', error);
      throw error;
    }
  }
};