import type { StaffUpdateData } from './types';

export function validateStaffData(data: StaffUpdateData): string | null {
  if (!data.name?.trim()) {
    return 'Name is required';
  }

  if (!data.email?.trim()) {
    return 'Email is required';
  }

  if (!data.role?.trim()) {
    return 'Role is required';
  }

  return null;
}