import React, { useState } from 'react';
import { Input } from '../../ui/Input';
import { Button } from '../../ui/Button';
import { WorkingHours } from './WorkingHours';
import { validateStaffForm } from './validation';
import type { StaffFormData, StaffFormProps } from './types';
import type { FormErrors } from './validation';

const DEFAULT_SCHEDULE = {
  Monday: { start: '09:00', end: '17:00', enabled: true },
  Tuesday: { start: '09:00', end: '17:00', enabled: true },
  Wednesday: { start: '09:00', end: '17:00', enabled: true },
  Thursday: { start: '09:00', end: '17:00', enabled: true },
  Friday: { start: '09:00', end: '17:00', enabled: true },
  Saturday: { start: '09:00', end: '17:00', enabled: false },
  Sunday: { start: '09:00', end: '17:00', enabled: false },
};

export function StaffForm({ initialData, onSubmit, onCancel, isLoading }: StaffFormProps) {
  const [formData, setFormData] = useState<StaffFormData>({
    name: initialData?.name ?? '',
    email: initialData?.email ?? '',
    phone: initialData?.phone ?? '',
    role: initialData?.role ?? '',
    qualifications: initialData?.qualifications ?? '',
    schedule: initialData?.schedule ?? DEFAULT_SCHEDULE,
  });
  
  const [errors, setErrors] = useState<FormErrors>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationErrors = validateStaffForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await onSubmit(formData);
    } catch (error: any) {
      console.error('Form submission error:', error);
      setErrors({ 
        submit: error.message || 'Failed to save staff member' 
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {errors.submit && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
          {errors.submit}
        </div>
      )}

      <Input
        label="Full Name"
        value={formData.name}
        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
        error={errors.name}
        required
        disabled={isLoading}
      />

      <Input
        label="Email"
        type="email"
        value={formData.email}
        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
        error={errors.email}
        required
        disabled={isLoading}
      />

      <Input
        label="Phone"
        type="tel"
        value={formData.phone}
        onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
        error={errors.phone}
        disabled={isLoading}
      />

      <Input
        label="Role"
        value={formData.role}
        onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
        error={errors.role}
        required
        disabled={isLoading}
      />

      <WorkingHours
        schedule={formData.schedule}
        onChange={(schedule) => setFormData(prev => ({ ...prev, schedule }))}
        disabled={isLoading}
      />

      <div className="flex justify-end space-x-4">
        <Button type="button" variant="secondary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button type="submit" isLoading={isLoading}>
          {initialData ? 'Save Changes' : 'Add Staff Member'}
        </Button>
      </div>
    </form>
  );
}