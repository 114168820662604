import React from 'react';
import { Link } from 'react-router-dom';

export function FooterLegal() {
  return (
    <div className="space-y-4">
      <h3 className="text-sm font-semibold text-gray-900">Legal</h3>
      <ul className="space-y-3">
        <li>
          <Link to="/terms" className="text-sm text-gray-500 hover:text-gray-900">
            Terms & Conditions
          </Link>
        </li>
        <li>
          <Link to="/privacy" className="text-sm text-gray-500 hover:text-gray-900">
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/contact" className="text-sm text-gray-500 hover:text-gray-900">
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
}